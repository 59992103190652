import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

// import FAQs from "../../components/pricing/faqs"
import Plans from "../../components/pricing/plans"
import CTA from "../../components/pricing/cta";
// import ScrollingTestimonials from "../../components/home/testimonialsScroll";
import FamewallEmbed from 'react-famewall'
import Header from '../../components/blog/header';


const ToolsPage = (props) => {

  return (
    <Layout
      title="Reddit Tools" subtitle={'Free tools for Reddit'}
      // includeBanner={false} lightHeader={false}
      seoComponent={<SEO title="Free Reddit Tools" path={'/tools/'} description="Explore free tools by GummySearch, no account required" />}
    >
      <div className="content-body markdown-body">
        <Header title="Free Reddit Tools" />
      </div>

      <div>
        These tools are free to use and do not require a GummySearch account.<br/>
        They are also <b>very</b> new and getting better every day. Enjoy!
      </div>

      <div className="mt-8 h-96 text-xl">
        <div>
          <Link to="/tools/top-subreddits/" className="font-semibold border-b-2 border-dotted border-cyan-800 hover:border-cyan-500">Top Subreddits List</Link>
        </div>
        <div>
          <Link to="/tools/subreddit-finder/" className="font-semibold border-b-2 border-dotted border-cyan-800 hover:border-cyan-500">Subreddit Finder</Link>
        </div>
        <div>
          <Link to="/tools/best-products/" className="font-semibold border-b-2 border-dotted border-cyan-800 hover:border-cyan-500">Best Products on Reddit</Link>
        </div>
      </div>
      
    </Layout>
  )
}


export default ToolsPage;
